import { Unsubscribe } from 'firebase/auth'
import { GeoPoint } from 'firebase/firestore'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { FirebaseService } from '../_firebase'

export interface Scooter {
    id: string
    plate: string
    onRide: boolean
    available: boolean
}

export interface StationData {
    id: string
    name: string
    manager: string
    location: GeoPoint
    dayStart: string
    dayEnd: string
    scooters?: Scooter[]
}

interface EndScooterRidePayload {
    bookingId: string
    userId: string
}

interface StationInterface {
    addScooter: (stationId: string, scooter: Partial<Scooter>) => Promise<void>
    getAllScooters: (stationId: string) => Promise<Scooter[]>
    turnOffScooter: (scooterId: string, stationId: string) => Promise<void>
    turnOnScooter: (scooterId: string, stationId: string) => Promise<void>
    endScooterRide: HttpsCallable<EndScooterRidePayload, undefined>
    subscribeAllScooters: (
        stationId: string,
        snapshotCallback: (_: Scooter[]) => void
    ) => Unsubscribe
}

export class Station
    extends FirebaseService<StationData, Scooter>
    implements StationInterface
{
    endScooterRide: HttpsCallable<EndScooterRidePayload, undefined>

    constructor() {
        super('stations')
        this.endScooterRide = httpsCallable(this.functions, 'endScooterRide')
    }

    async addScooter(stationId: string, scooter: Partial<Scooter>) {
        try {
            await this.addToSubcollection('scooters', stationId, {
                ...scooter,
                onRide: false,
                available: false,
            })
        } catch (e) {
            throw e
        }
    }

    async getAllScooters(stationId: string) {
        try {
            const scooters = await this.getAllFromSubCollection(
                'scooters',
                stationId
            )
            return scooters
        } catch (e) {
            throw e
        }
    }

    subscribeAllScooters(
        stationId: string,
        snapshotCallback: (_: Scooter[]) => void
    ) {
        try {
            const scooterCollectionReference = this.createSubCollection(
                'scooters',
                stationId
            )
            const unsubscribe = this.subscribeToSubCollection(
                scooterCollectionReference,
                (snaphot) => {
                    const scooters = snaphot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }))
                    snapshotCallback(scooters)
                }
            )
            return unsubscribe
        } catch (e) {
            throw e
        }
    }

    async turnOffScooter(scooterId: string, stationId: string) {
        try {
            await this.updateSubCollectionDoc(
                scooterId,
                stationId,
                'scooters',
                {
                    available: false,
                }
            )
        } catch (e) {
            throw e
        }
    }

    async turnOnScooter(scooterId: string, stationId: string) {
        try {
            await this.updateSubCollectionDoc(
                scooterId,
                stationId,
                'scooters',
                {
                    available: true,
                }
            )
        } catch (e) {
            throw e
        }
    }
}
