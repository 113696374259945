import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { FC } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f07927",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ffbf00",
      contrastText: "#FFFFFF",
    },
  },
});

export const MuiProvider: FC = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
