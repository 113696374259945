import { FC, useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { AuthContext } from '../providers/contexts/Auth'

interface PrivateRouteInterface extends RouteProps {
  protectedRoute: boolean
}

export const PrivateRoute: FC<PrivateRouteInterface> = ({
  children,
  protectedRoute,
  ...rest
}) => {
  const { user } = useContext(AuthContext)
  // Protected Route but user not logged in
  if (protectedRoute && !user) {
    return <Redirect to="/login" />
  }

  // Not Protected Route but User Logged in
  if (!protectedRoute && !!user) {
    return <Redirect to="/" />
  }
  return <Route {...rest} render={() => <>{children}</>} />
}
