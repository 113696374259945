import { FirebaseService } from '../_firebase'

interface Slots {
    [key: string]: boolean
}

export interface Boat {
    id: string
    boatNumber: string
    contactPerson: string
    phone: string
    bookings: {
        [key: string]: Slots
    }
}

export interface Image {
    uri: string
}

export interface CategoryData {
    id: string
    name: string
    description: string
    capacity: string
    operatingType: string
    images: Image[]
    boats: Boat[]
}

export class Category extends FirebaseService<CategoryData, Boat> {
    constructor() {
        super('categories')
    }
}
