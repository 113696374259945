import { CashPayment } from './cash-payments'
import { Category } from './category'
import { Ghats } from './ghats'
import { OffDays } from './off-days'
import { Price } from './prices'
import { Route } from './route'
import { Station } from './station'
import { Storage } from './storage'
import { User } from './users'

export const UserService = new User()

export const StationService = new Station()

export const OffDaysService = new OffDays()

export const CategoryService = new Category()

export const StorageService = new Storage()

export const GhatsService = new Ghats()

export const RouteService = new Route()

export const PriceService = new Price()

export const CashPaymentService = new CashPayment()
