import { GeoPoint } from 'firebase/firestore'
import { FirebaseService } from '../_firebase'

export interface GhatData {
    id: string
    name: string
    location: GeoPoint
}

interface GhatsInterface {}

export class Ghats extends FirebaseService<GhatData> implements GhatsInterface {
    constructor() {
        super('ghats')
    }
}
