import { FirebaseService } from '../_firebase'

export interface RouteData {
  id: string
  name: string
  slots: number
}

interface RouteInterface {}

export class Route
  extends FirebaseService<RouteData>
  implements RouteInterface
{
  constructor() {
    super('routes')
  }
}
