import { firebaseApp } from '../firebase.config'
import {
    deleteObject,
    FirebaseStorage,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytesResumable,
    UploadTaskSnapshot,
} from 'firebase/storage'

import { v4 } from 'uuid'

interface StorageInterface {
    uploadFile: (
        file: File,
        progressCallback: (snapshot: UploadTaskSnapshot) => void
    ) => Promise<string>
    deleteFile: (uri: string) => Promise<void>
}

export class Storage implements StorageInterface {
    storage: FirebaseStorage

    constructor() {
        this.storage = getStorage(firebaseApp)
    }

    async uploadFile(
        file: File,
        progressCallback: (snapshot: UploadTaskSnapshot) => void
    ) {
        const storageRef = ref(this.storage, `categories/${v4()}`)

        const uploadTask = uploadBytesResumable(storageRef, file)

        return await new Promise<string>((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                progressCallback,
                (error) => {
                    reject(error.message)
                },
                async () => {
                    const downloadUrl = await getDownloadURL(
                        uploadTask.snapshot.ref
                    )
                    resolve(downloadUrl)
                }
            )
        })
    }

    async deleteFile(uri: string) {
        try {
            const storageRef = ref(this.storage, uri)
            await deleteObject(storageRef)
        } catch (e) {
            throw e
        }
    }
}
