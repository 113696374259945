import { Box } from "@mui/material";

import logoWhite from "./../../../assets/images/logo-white.png";

import "./Loader.scss";

export const Loader = () => {
  return (
    <Box className="loader-view">
      <img src={logoWhite} alt="Kashi Riders" />
    </Box>
  );
};
