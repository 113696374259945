import { FC } from "react";
import { AuthProvider } from "./contexts/Auth";
import { ToastProvider } from "./contexts/Toast";
import { MuiProvider } from "./MuiProvider";

export const Providers: FC = ({ children }) => {
  return (
    <MuiProvider>
      <ToastProvider>
        <AuthProvider>{children}</AuthProvider>
      </ToastProvider>
    </MuiProvider>
  );
};
