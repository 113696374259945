import { FirebaseService } from '../_firebase'
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  Unsubscribe,
  User as UserType,
} from 'firebase/auth'
import {
  query,
  where,
  Unsubscribe as FirestoreUnsubscribe,
  serverTimestamp,
  getDocs,
} from 'firebase/firestore'
import { ShowToast } from '../../providers/contexts/Toast'
import dayjs from 'dayjs'

export enum BookingPeriod {
  DAY = 'day',
  MONTH = 'month',
}

export interface Booking {
  id: string
  userId: string
  bookingTime: Date
  stationId?: string
  scooterId?: string
  rideStartTime?: Date
  rideEndTime?: Date
  cost?: number
  discountCost?: number
  coupon?: string
  paymentId: string | null
  routeId?: string
  boatId?: string
  bookingSlot?: string
  categoryId?: string
  ghatId?: string
  dateString?: string
  otp?: string
  otpVerified?: boolean
  isCashPayment: boolean
}

export interface UserData {
  id: string
  admin: boolean
  email: string
  name: string
  phone: string
  drivingLicenseNumber: string | null
  adhaarCardNumber: string | null
  passportNumber: string | null
}

type AuthCallBack = (user: UserType | null) => Promise<void>

interface UserInterface {
  auth: Auth
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  onAuthChange: (callBack: AuthCallBack, shotToast: ShowToast) => Unsubscribe
  listenAllBookings: (
    snapshotCallback: (bookings: Booking[]) => void
  ) => FirestoreUnsubscribe
  startScooterRide: (bookingId: string, userId: string) => Promise<void>
  getAllBookings: (BookingPeriod: BookingPeriod) => Promise<Booking[]>
}

export class User
  extends FirebaseService<UserData, Booking>
  implements UserInterface
{
  auth: Auth
  constructor() {
    super('users')
    this.auth = getAuth()
  }

  async login(email: string, password: string) {
    try {
      await signInWithEmailAndPassword(this.auth, email, password)
    } catch (e) {
      throw e
    }
  }

  async logout() {
    try {
      await signOut(this.auth)
    } catch (e) {
      throw e
    }
  }

  onAuthChange(callBack: AuthCallBack, showToast: ShowToast) {
    return onAuthStateChanged(this.auth, async (user) => {
      if (user) {
        try {
          const userInfo = await this.getOne(user.uid)
          if (userInfo.admin) {
            callBack(user)
          } else {
            await this.logout()
            throw new Error('Invalid User Login')
          }
        } catch (e) {
          e instanceof Error && showToast(e.message, 'error')
          callBack(null)
        }
      } else {
        callBack(null)
      }
    })
  }

  listenAllBookings(snapshotCallback: (_: Booking[]) => void) {
    const bookingCollectionGroupQuery = query<Booking>(
      this.createSubCollectionGroup('bookings'),
      where('paymentId', '==', null)
    )
    const unsubscribe = this.subscribeToSubCollection(
      bookingCollectionGroupQuery,
      (snapshot) => {
        const bookings = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        snapshotCallback(bookings)
      }
    )
    return unsubscribe
  }

  async startScooterRide(bookingId: string, userId: string) {
    try {
      this.updateSubCollectionDoc(bookingId, userId, 'bookings', {
        rideStartTime: serverTimestamp(),
      })
    } catch (e) {
      throw e
    }
  }

  async cancelScooterRide(bookingId: string, userId: string) {
    await this.deleteSubCollectionDoc(bookingId, userId, 'bookings')
  }

  /**
   * getAllBookings
   * get all the bookings on the base of the BookingPeriod
   * DAY - gives the booking for current day
   * MONTH - gives the booking for current month
   * @params bookingPeriod: BookingPeriod
   * @returns Promise<Bookings[]>
   */
  async getAllBookings(bookingPeriod: BookingPeriod) {
    try {
      const today = dayjs()
      const lowerDate =
        bookingPeriod === BookingPeriod.DAY
          ? today
          : today.subtract(today.date(), 'day')
      const upperDate = today.add(1, 'day')
      const bookingCollectionGroupQuery = query<Booking>(
        this.createSubCollectionGroup('bookings'),
        where(
          'bookingTime',
          '>=',
          new Date(`${lowerDate.format('YYYY-MM-DD')} 00:00`)
        ),
        where(
          'bookingTime',
          '<=',
          new Date(`${upperDate.format('YYYY-MM-DD')} 00:00`)
        )
      )
      const bookings = await getDocs(bookingCollectionGroupQuery)
      return bookings.docs
        .map((booking) => ({
          ...booking.data(),
          bookingTime: (booking.data().bookingTime as any).toDate(),
          id: booking.id,
        }))
        .reverse()
    } catch (e) {
      throw e
    }
  }
}
