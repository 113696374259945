import { Alert, AlertColor, Snackbar } from "@mui/material";
import { createContext, FC, useCallback, useState } from "react";

export type ShowToast = (message: string, type?: AlertColor) => void;

interface ToastContextInterface {
  showToast: ShowToast;
}

export const ToastContext = createContext<ToastContextInterface>({
  showToast: (_) => {},
});

interface ToastType {
  message: string;
  type?: AlertColor;
}

const DEFAULT_TOAST_VALUE = {
  message: "",
};

export const ToastProvider: FC = ({ children }) => {
  const [toast, setToast] = useState<ToastType>(DEFAULT_TOAST_VALUE);

  /**
   * showToast
   *
   * this will show a snackbar at the bottom of the screen
   * and automatically hide it after 3 seconds
   *
   * @param message the message that needs to be shown in the snackbar
   * @param type AlertColor defines the type of toast shown
   *
   */
  const showToast = useCallback(
    (message: string, type?: AlertColor) => {
      setToast({ message, type });
    },
    [setToast]
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        open={toast.message.length > 0}
        onClose={() => setToast(DEFAULT_TOAST_VALUE)}
        autoHideDuration={3000}
      >
        <Alert severity={toast.type}>{toast.message}</Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
