import { FirebaseService } from "../_firebase";

export enum OffDayType {
  "SCOOTER" = "SCOOTER",
  "BOAT" = "BOAT",
}

export interface OffDaysData {
  id: string;
  date: Date;
  reason: string;
  type: OffDayType;
}

interface OffDaysInterface {}

export class OffDays
  extends FirebaseService<OffDaysData>
  implements OffDaysInterface
{
  constructor() {
    super("offDays");
  }
}
