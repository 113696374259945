import { FirebaseService } from '../_firebase'

export interface CashPaymentData {
  id: string
  amount: number
  reason: string
  bookingId: string
  userId: string
}

interface CashPaymentsInterface {}

export class CashPayment
  extends FirebaseService<CashPaymentData>
  implements CashPaymentsInterface
{
  constructor() {
    super('cashPayments')
  }
}
