import {
  addDoc,
  collection,
  CollectionReference,
  Firestore,
  getDocs,
  getFirestore,
  QuerySnapshot,
  WithFieldValue,
  onSnapshot,
  Unsubscribe,
  collectionGroup,
  doc,
  updateDoc,
  Query,
  UpdateData,
  getDoc,
  query,
  orderBy,
  deleteDoc,
} from 'firebase/firestore'
import { firebaseApp } from './firebase.config'
import { Functions, getFunctions } from 'firebase/functions'

interface FirebaseServiceInterface<Data, SubData> {
  firestore: Firestore

  functions: Functions

  collection: CollectionReference<Data>

  getOne: (docId: string) => Promise<Data>

  getAll: () => Promise<Data[]>

  add: (data: WithFieldValue<Partial<Data>>) => Promise<string>

  update: (docId: string, data: UpdateData<Data>) => Promise<void>

  createSubCollection: (
    subCollectionName: string,
    parentId: string
  ) => CollectionReference<SubData>

  createSubCollectionGroup: (subCollectionName: string) => Query<SubData>

  addToSubcollection: (
    subCollectionName: string,
    parentId: string,
    data: WithFieldValue<Partial<SubData>>
  ) => Promise<void>

  getAllFromSubCollection: (
    subCollectionName: string,
    parentId: string
  ) => Promise<SubData[]>

  subscribeToSubCollection: (
    reference: CollectionReference<SubData> | Query<SubData>,
    snapshotCallback: (snapshot: QuerySnapshot<SubData>) => void
  ) => Unsubscribe

  updateSubCollectionDoc: (
    docId: string,
    parentId: string,
    subCollectionName: string,
    data: UpdateData<SubData>
  ) => Promise<void>

  deleteSubCollectionDoc: (
    docId: string,
    parentId: string,
    subCollectionName: string
  ) => Promise<void>
}

export class FirebaseService<Data, SubData = Data>
  implements FirebaseServiceInterface<Data, SubData>
{
  firestore: Firestore
  collection: CollectionReference<Data>
  functions: Functions

  constructor(collectionName: string) {
    this.firestore = getFirestore(firebaseApp)
    this.functions = getFunctions(firebaseApp, 'asia-south1')
    this.collection = collection(
      this.firestore,
      collectionName
    ) as CollectionReference<Data>
  }

  /**
   * createSubCollection
   * creates a subcollection with the given subcollection
   * name with reference to the parent collection
   * @param subCollectionName name of the subcollection
   * @param document id of the parent
   */
  createSubCollection(subCollectionName: string, parentId: string) {
    return collection(
      this.firestore,
      `${this.collection.path}/${parentId}/${subCollectionName}`
    ) as CollectionReference<SubData>
  }

  /**
   * createSubCollectionGroup
   * creates a collectionGroup for the given collection name
   * @param subCollectionName name of the collection one which the query will be run
   */
  createSubCollectionGroup(subCollectionName: string) {
    return collectionGroup(this.firestore, subCollectionName) as Query<SubData>
  }

  /**
   * add
   * adds a new document to the collection of documents
   * @param data the data of Data type to add to the collection
   */
  async add(data: WithFieldValue<Partial<Data>>) {
    try {
      const document = await addDoc(this.collection, data)
      return document.id
    } catch (e) {
      throw e
    }
  }

  /**
   * update
   * updates the document with the partial data for the collection
   * datatype
   * @param docId Id of the document to be updated
   * @param data partial or complete document to be updated
   */
  async update(docId: string, data: UpdateData<Data>) {
    try {
      await updateDoc<Data>(doc(this.collection, docId), data)
    } catch (e) {
      throw e
    }
  }

  /**
   * addToSubcollection
   * adds the data to the subCollection intialized by calling
   * createSubCollection
   * @param subCollectionName name of the subcollection
   * @param parentId document id for the parent collection
   * @data SubData data that needs to inserted in the document
   */
  async addToSubcollection(
    subCollectionName: string,
    parentId: string,
    data: WithFieldValue<Partial<SubData>>
  ) {
    try {
      await addDoc(this.createSubCollection(subCollectionName, parentId), data)
    } catch (error) {
      throw error
    }
  }

  /**
   * getOne
   * fetches one document from the collection
   * @param docId id of the document that needs to be fetched
   */
  async getOne(docId: string) {
    try {
      const documentReference = await getDoc(doc(this.collection, docId))
      if (!documentReference.exists()) {
        throw new Error(
          `Requested ${this.collection.path} does not exists. Please try again`
        )
      }
      return {
        ...documentReference.data(),
        id: documentReference.id,
      } as Data
    } catch (e) {
      throw e
    }
  }

  /**
   * getAll
   * fetches all documents from the collection
   */
  async getAll() {
    try {
      const docs = await getDocs(this.collection)
      const docData = docs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      return docData
    } catch (e) {
      throw e
    }
  }

  /**
   * getAllSorted
   * fetch all documents with sort enabled
   */
  async getAllSorted(orderField: string) {
    try {
      const docs = await getDocs(
        query<Data>(this.collection, orderBy(orderField))
      )
      const docData = docs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      return docData
    } catch (e) {
      throw e
    }
  }

  async getAllFromSubCollection(subCollectionName: string, parentId: string) {
    try {
      const docs = await getDocs(
        this.createSubCollection(subCollectionName, parentId)
      )
      const docData = docs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      return docData
    } catch (e) {
      throw e
    }
  }

  /**
   * subscribeToSubCollection
   * listen to changes to the documents of the sub collection
   * this can be a complete collection or a query on the collection
   * @param reference Collection Reference or Query on the subcollection
   * @param snapshotCallback the method called when change is detected
   */
  subscribeToSubCollection(
    reference: CollectionReference<SubData> | Query<SubData>,
    snapshotCallback: (snapshot: QuerySnapshot<SubData>) => void
  ) {
    const unsubscribe = onSnapshot(reference, snapshotCallback)
    return unsubscribe
  }

  /**
   * updateSubCollectionDoc
   * updates document inside the sub collection
   * @param docId document Id of the updating document
   * @param parentId document id of the parent document
   * @param subCollectionName name of of the subcollection
   * @param data updated values of the document( complete | partial )
   */
  async updateSubCollectionDoc(
    docId: string,
    parentId: string,
    subCollectionName: string,
    data: UpdateData<SubData>
  ) {
    try {
      await updateDoc<SubData>(
        doc<SubData>(
          this.createSubCollection(subCollectionName, parentId),
          docId
        ),
        data
      )
    } catch (e) {
      throw e
    }
  }

  /**
   * deleteSubCollectionDoc
   * deletes document inside the sub collection
   * @param docId document Id of the updating document
   * @param parentId document id of the parent document
   * @param subCollectionName name of of the subcollection
   */
  async deleteSubCollectionDoc(
    docId: string,
    parentId: string,
    subCollectionName: string
  ) {
    try {
      await deleteDoc(
        doc<SubData>(
          this.createSubCollection(subCollectionName, parentId),
          docId
        )
      )
    } catch (e) {
      throw e
    }
  }
}
