import { createContext, FC, useContext, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { UserService } from "../../services";
import { Loader } from "../../components/common/loader";
import { ToastContext } from "./Toast";

interface AuthContextInterface {
  user: User | null;
}

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
});

export const AuthProvider: FC = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const { showToast } = useContext(ToastContext);

  const callBack = async (user: User | null) => {
    setUser(user);
    setInitialized(true);
  };

  useEffect(() => {
    return UserService.onAuthChange(callBack, showToast);
  }, [showToast]);

  return (
    <AuthContext.Provider value={{ user }}>
      {initialized ? <>{children}</> : <Loader />}
    </AuthContext.Provider>
  );
};
