import { getDocs, query, where } from 'firebase/firestore'
import { FirebaseService } from '../_firebase'

export interface PriceData {
    id: string
    categoryId: string
    routeId: string
    price: number
}

interface PriceInterface {
    addNewPrice: (data: Partial<PriceData>) => Promise<void>
}

export class Price
    extends FirebaseService<PriceData>
    implements PriceInterface
{
    constructor() {
        super('prices')
    }

    async addNewPrice(data: Partial<PriceData>) {
        const documentReference = await getDocs(
            query(
                this.collection,
                where('categoryId', '==', data.categoryId),
                where('routeId', '==', data.routeId)
            )
        )
        if (documentReference.size > 0) {
            this.update(documentReference.docs[0].id, data)
        } else {
            this.add(data)
        }
    }
}
