import { lazy, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Loader } from "../components/common/loader";
import { PrivateRoute } from "./PrivateRoute";

// Lazy Loading
const LoginPage = lazy(() => import("./pages/login"));
const DashboardPage = lazy(() => import("./pages/dashboard"));

export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PrivateRoute
            protectedRoute={false}
            path="/login"
            component={LoginPage}
          />
          <PrivateRoute
            protectedRoute={true}
            path="/"
            component={DashboardPage}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
